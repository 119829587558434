import { setPublicPath } from "systemjs-webpack-interop";
setPublicPath("document-editor-ui!sofe");

export const getDocumentEditor = () => {
  // prettier-ignore
  return import(/* webpackChunkName: "document-editor" */ 'src/document-editor/document-editor.component').then(
    mod => mod
  );
};

export const getDocumentEditorOverlay = () => {
  // prettier-ignore
  return import(/* webpackChunkName: "document-editor-overlay" */ 'src/document-editor/document-editor-overlay.component')
    .then(mod => mod);
};

export const getSaveToFilesModal = () => {
  // prettier-ignore
  return import(/* webpackChunkName: "save-to-files" */ 'src/document-tools/save-to-files-modal.component').then(
    mod => mod
  );
};

export const getNewLetterFromTemplateModal = () => {
  // prettier-ignore
  return import(/* webpackChunkName: "letter-from-template" */ 'src/letters/new-letter-modal.component').then(
    mod => mod
  );
};
